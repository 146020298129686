<template>
  <div class="examine_detail" v-loading="loading">
    <back />

    <div class="content globle_border">
      <el-form ref="form1" class="form" :model="form" label-position="left" label-width="170px" disabled>
        <div class="title1">饭堂信息</div>
        <el-form-item label="营业执照">
          <el-image class="busilicense" fit="cover" :src="form.businessLicenseUrl" :preview-src-list="[form.businessLicenseUrl]"></el-image>
        </el-form-item>
        <el-form-item label="饭堂名称">
          <el-input v-model="form.shopName"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.creditCode"></el-input>
        </el-form-item>
        <el-form-item label="关联学校">
          <el-input v-model="form.schoolName"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.contactMan"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.contactTel"></el-input>
        </el-form-item>
        <el-form-item label="饭堂地址">
          <el-input v-model="form.local"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <!-- <el-input type="textarea" v-model="form.introduction" :autosize="{ minRows: 5, maxRows: 15 }"></el-input> -->
          <div class="Runecloth">
            <div v-html="form.introduction"></div>
          </div>
        </el-form-item>
        <div v-if="form.status != 0">
          <el-form-item label="审核状态">
            <el-input v-model="mapStatus"></el-input>
          </el-form-item>
          <el-form-item label="审核时间">
            <el-input v-model="form.updatedTime"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="不通过原因" v-if="form.status == 2">
          <el-input type="textarea" v-model="form.reason" :autosize="{ minRows: 5, maxRows: 15 }"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="btn" v-if="form.status == 0">
      <el-button type="danger" plain @click="handleApprove(2)">不通过</el-button>
      <el-button type="primary" @click="handleApprove(1)">通过</el-button>
    </div>

    <el-dialog title="审核不通过" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form ref="examineForm" class="examineForm" :rules="rules" :model="examineForm" label-position="left" label-width="130px">
        <el-form-item label="不通过原因" prop="loseContent">
          <el-input type="textarea" v-model="examineForm.loseContent" :autosize="{ minRows: 4, maxRows: 15 }"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('examineForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      loading: false,
      submitLoding: false,
      dialogVisible: false,
      colorStatus: ["red", "green", "gray"],
      mapStatus: "",

      form: {},
      examineForm: {
        loseContent: "",
      },
      rules: {
        loseContent: [{ required: true, message: "请输入不通过原因", trigger: "submit" }],
      },
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getSupplierDetail()
  },
  methods: {
    getSupplierDetail() {
      this.loading = true
      this.$axios
        .get(`${this.$api.shopInfo}/${this.id}`)
        .then(res => {
          const { result } = res.data
          this.form = result
          this.form.local = result.province + result.city + result.area + result.address
          this.mapStatus = this.form.status == 0 ? "待审核" : this.form.status == 1 ? "审核通过" : this.form.status == 2 ? "审核不通过" : "审核通过"
          this.form.introduction = this.form.introduction.replace(/float: left;/g, "")
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 审核通过/不通过
    handleApprove(val) {
      if (val == 1) {
        this.$confirm(`是否确认审核通过?`, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.$axios
              .put(this.$api.auditing, {
                shopId: this.id,
                status: "1",
              })
              .then(res => {
                if (res.data.code == 100) {
                  this.$message.success(res.data.desc)
                }
                this.$router.go(-1)
              })
              .catch(err => {
                this.$message.error(err.data.desc)
              })
          })
          .catch(() => {})
      } else {
        this.dialogVisible = true
      }
    },
    onCancel() {
      this.dialogVisible = false
    },
    // 提交表单
    submitForm(formName) {
      this.submitLoding = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .put(this.$api.auditing, {
              shopId: this.id,
              reason: this.examineForm.loseContent,
              status: "2",
            })
            .then(res => {
              this.$message.success(res.data.desc)
              this.getSupplierDetail()
              this.submitLoding = false
              this.dialogVisible = false
              this.$router.go(-1)
            })
            .catch(err => {
              this.$message.error(err.data.desc)
            })
        } else {
          this.submitLoding = false
          this.$message.error("提交失败")
          return false
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.examine_detail {
  .content {
    padding: 60px 190px;
  }

  .form {
    // ::v-deep .el-input__inner{
    //   border: none !important;
    // }
    .red {
      ::v-deep .el-input__inner {
        color: #eb2500 !important;
      }
    }

    .Runecloth {
      width: 1000px;
      border-radius: 10px;
      border: 1px solid #e5e7e6;
      padding: 10px 10px;
      ::v-deep img {
        object-fit: contain;
        max-width: 100%;
      }
    }

    .gray {
      ::v-deep .el-input__inner {
        color: #68706e !important;
      }
    }

    .title1,
    .title2 {
      position: relative;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .title1::before,
    .title2::before {
      content: "";
      position: absolute;
      top: 0;
      left: -12px;
      width: 4px;
      height: 22px;
      background-color: #c27c88;
    }

    .title2 {
      margin-top: 60px;
    }

    ::v-deep .el-input {
      width: 650px;
    }

    ::v-deep .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 40px;
    }

    ::v-deep .el-textarea {
      width: 650px;
    }

    ::v-deep .el-input__inner,
    ::v-deep .el-textarea__inner {
      background: #fff;
      border-radius: 10px;
      border: 1px solid #e5e7e6;
    }

    ::v-deep .el-input.is-disabled .el-input__inner,
    ::v-deep .el-textarea.is-disabled .el-textarea__inner {
      color: #1a1a1a;
    }

    .logo {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 133px;
      height: 100px;
      border-radius: 4px;
      border: none !important;
    }
  }

  .times {
    padding: 30px;
    margin-top: 30px;
    box-sizing: border-box;

    .exinfo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ::v-deep .el-form-item__label {
        padding-right: 16px;
      }

      ::v-deep .el-form-item {
        margin: 0;
      }
    }

    .lose_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .label {
        width: 90px;
        padding-right: 16px;
      }

      .label,
      ::v-deep .el-textarea__inner {
        color: #eb2500 !important;
      }

      ::v-deep .el-form-item__label {
        padding-right: 16px;
      }

      ::v-deep .el-form-item {
        display: block;
        margin: 0;
      }
    }

    .status {
      width: 202px;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #e5e7e6;
      border-radius: 10px;
      box-sizing: border-box;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #c27c88;
    }

    .gray {
      color: #68706e;
    }
  }

  .btn {
    text-align: center;
    margin: 30px 0;

    .el-button {
      width: 150px;
      margin: 0 20px;
    }
  }
}
</style>
